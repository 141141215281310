const parseYoutubeDuration = (duration: string): string => {
  const match = duration
    .match(/PT(\d+H)?(\d+M)?(\d+S)?/)
    ?.slice(1)
    .map((x) => {
      if (x !== null) {
        return x?.replace(/\D/, '')
      }
    })

  const hours = match?.[0] ? parseInt(match[0]) : 0
  const minutes = match?.[1] ? parseInt(match[1]) : 0
  const seconds = match?.[2] ? parseInt(match[2]) : 0

  const hoursToString = hours === 0 ? '' : `${hours}:`
  const minutesToString =
    minutes < 10 ? `${hours === 0 ? '' : '0'}${minutes}:` : `${minutes}:`
  const secondsToString = seconds < 10 ? `0${seconds}` : `${seconds}`

  return `${hoursToString}${minutesToString}${secondsToString}`
}

export default parseYoutubeDuration
