interface IConfiguration {
  API_URL: string
  MEDIA_URL: string
  YOUTUBE_API_KEY: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
  API_URL: process.env.API_URL as string,
  MEDIA_URL: process.env.MEDIA_URL as string,
  YOUTUBE_API_KEY: process.env.YOUTUBE_API_KEY as string,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
  REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
  ...compiletimeConfiguration,
  ...APP_CONFIGURATION,
})

export default getConfig
